import deFabriekLogo from "assets/images/projects/svdefabriek.png";
import recognizeLogo from "assets/images/logos/recognize.png";
import modditLogo from "assets/images/logos/moddit.jpeg";
import jopaLogo from "assets/images/logos/jopa.jpg";

export const projectsList = [
  {
    id: 7,
    title: "CMS - Jopa",
    logo: jopaLogo,
    blurHash: "L4ADc400P*Zi4Tu1y;Qo00pH#YXl",
    link: "https://tijmendejong.nl/",
    desc: `CMS for Jopa to manage their products, orders, customers etc.`,
    technologies: ["php", "laravel", "filament", "tailwind"]
  },
  {
    id: 6,
    title: "Moddit Finance API - Moddit Digital Agency",
    logo: modditLogo,
    blurHash: "L4ADc400P*Zi4Tu1y;Qo00pH#YXl",
    link: "https://tijmendejong.nl/",
    desc: `API interface build for Moddit Internal Finance connections.`,
    technologies: ["php", "laravel", "lighthouse", "graphql", "bootstrap", "vue.js"]
  },
  {
    id: 5,
    title: "Mijn Moddit - Moddit Digital Agency",
    logo: modditLogo,
    blurHash: "L4ADc400P*Zi4Tu1y;Qo00pH#YXl",
    link: "https://tijmendejong.nl/",
    desc: `Web application build for Moddit Intern.`,
    technologies: ["php", "laravel", "vue.js"]
  },
  {
    id: 4,
    title: "Management Tool - SV de Fabriek",
    logo: deFabriekLogo,
    blurHash: "L4ADc400P*Zi4Tu1y;Qo00pH#YXl",
    link: "https://tijmendejong.nl/",
    desc: `Web application build for SV de Fabriek.`,
    technologies: ["php", "laravel", "vue.js", "inertia"]
  },
  {
    id: 3,
    title: "Website - SV de Fabriek",
    logo: deFabriekLogo,
    blurHash: "L4ADc400P*Zi4Tu1y;Qo00pH#YXl",
    link: "https://defabriek.org/",
    desc: `Web application build for showing information about Skatepark de Fabriek.`,
    technologies: ["php", "laravel", "vue.js"]
  },
  {
    id: 2,
    title: "API - SV de Fabriek",
    logo: deFabriekLogo,
    blurHash: "L4ADc400P*Zi4Tu1y;Qo00pH#YXl",
    link: "https://tijmendejong.nl/",
    desc: `API application build for retrieving data of SV de Fabriek.`,
    technologies: ["php", "laravel"]
  },
  {
    id: 1,
    title: "Android App - Recognize",
    logo: recognizeLogo,
    blurHash: "L4ADc400P*Zi4Tu1y;Qo00pH#YXl",
    link: "https://tijmendejong.nl/",
    desc: `Android app build for Recognize.`,
    technologies: ["java", "maven", "android studio"]
  },
];
