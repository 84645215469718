import * as React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { VStack, HStack, Text, Tag, Link } from "@chakra-ui/react";
import LazyImage from "./lazy-image";
import {
    useColorModeValue,
} from "@chakra-ui/react";
import { getTagColor } from "style/theme";

interface HighLightProps {
    title: string;
    logo: string;
    blurHash: string;
    link: string;
    technologies: string[];
    description: string;
}

const HightLight = (props: HighLightProps) => {
    const [isOpen, setIsOpen] = React.useState(true);
    const toggleOpen = () => setIsOpen(!isOpen);
    const { logo, blurHash, link, technologies, title, description } = props;

    return (
        <motion.div layout onClick={toggleOpen}>
            <HStack
                p={4}
                bg={useColorModeValue("white", "gray.800")}
                rounded="xl"
                borderWidth="1px"
                borderColor={useColorModeValue("gray.100", "gray.700")}
                w="100%"
                h="100%"
                textAlign="left"
                align="start"
                spacing={4}
                cursor="pointer"
                _hover={{ shadow: "lg" }}
            >
                <LazyImage
                    src={logo}
                    blurHash={blurHash}
                    size="sm"
                    width={70}
                    layout="fixed"
                    rounded="md"
                />
                <VStack align="start" justify="flex-start">
                    <VStack spacing={0} align="start">
                        <motion.div layout>
                            <HStack>
                                <Text
                                    as={Link}
                                    href={link}
                                    fontWeight="bold"
                                    fontSize="md"
                                    noOfLines={1}
                                    onClick={e => e.stopPropagation()}
                                    isExternal
                                >
                                    { title }
                                </Text>
                                <HStack spacing="1">
                                    {
                                        technologies.map(tech => (
                                            <Tag size="sm" key={tech} colorScheme={getTagColor(tech)}>
                                                {tech}
                                            </Tag>
                                        ))
                                    }
                                </HStack>
                            </HStack>
                        </motion.div>
                        <AnimatePresence>
                            <motion.div
                                layout
                                initial={{ opacity: 1 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 1 }}
                            >
                                {!isOpen && (
                                    <Text fontSize="sm" noOfLines={{ base: 2 }}>
                                        {description}
                                    </Text>
                                )}
                            </motion.div>
                        </AnimatePresence>
                        <AnimatePresence>
                            <motion.div
                                layout
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                variants={{ exit: { transition: { staggerChildren: 0.1 } } }}
                            >
                                {isOpen && (
                                    <Text
                                        fontSize="sm"
                                    >
                                        <span>My latest project is a portfolio website built using React, TypeScript, Chakra UI as a component library, and Framer Motion for some sleek animations on the elements. Through this project, I learned a lot about building React components and got my first introduction to Chakra UI. It is a fantastic library for quickly creating clean and professional-looking websites.<br></br><br></br></span>
                                        Need a website to showcase your work? Don’t hesitate to contact me via my LinkedIn profile. Scroll to the bottom of this page to find my social media links 🔗
                                    </Text>
                                )
                                }
                            </motion.div>
                        </AnimatePresence>
                    </VStack>
                </VStack>
            </HStack>
        </motion.div>
    )
}

export default HightLight;